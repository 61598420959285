import React from 'react';
import './HomePage.css';
import { Carousel, Navbar, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import headerBGImage from '../images/image1.png';
import img5 from '../images/image5.jpg';
import img6 from '../images/image6.jpg';
import img7 from '../images/image7.jpg';
import img8 from '../images/image8.jpg';
import img9 from '../images/image9.jpg';
import img10 from '../images/image10.jpg';
import img11 from '../images/image11.jpg';
import img12 from '../images/image12.jpg';
import img13 from '../images/image13.jpg';
import img14 from '../images/image14.jpg';
import aboutImg from '../images/MapLocationWork.png'; 
import backgroundImg from '../images/BackgroundBC.png';
import logo from '../images/BWLogoSolo.png';

const HomePage = () => {
    return (
        <div className="home-container" style={{ backgroundImage: `url(${backgroundImg})` }}>
            <header className="home-header" style={{ backgroundImage: `url(${headerBGImage})` }}>
                <img src={logo} alt="Big Wave Marine Logo" className="header-logo" />
                <div className="header-text">
                    <h1>Big Wave Marine</h1>
                    <p>100% Mobile Boat Services!</p>
                    <p>Call Us: <a href="tel:+12536787750">(253) 678-7750</a> | 24/7 Watercraft Support</p>
                </div>
            </header>
            <Navbar expand="lg" className="navbar navbar-dark">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mx-auto">
                        <Nav.Link href="#about">About Us</Nav.Link>
                        <Nav.Link href="#services">Services</Nav.Link>
                        <Nav.Link href="#contact">Contact</Nav.Link>
                        <Nav.Link href="https://www.facebook.com/people/Big-Wave-Marine/61551088239734/" target="_blank">
                            <i className="fab fa-facebook"></i> Facebook
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <section className="about-section" id="about">
                <h2>About Us</h2>
                <div className="about-content">
                    <p>At Big Wave Marine, we bring 5-star quality marine repair to the PNW. <strong>We are 100% mobile!</strong> That means no more dragging your watercraft to sit at the shop for days. We bring a skilled mechanic to your driveway, garage, or dock and get you up and running as fast as possible. From Skiffs to Yachts, we can do it all. From mild to wild, we can do it all. Veteran-owned and operated, we offer military and public safety discounts!</p>
                    <img src={aboutImg} alt="About Us" className="about-image" />
                </div>
            </section>
            <section className="services-section" id="services">
                <h2>Services We Offer</h2>
                <div className="services-content">
                    <div className="services-carousel">
                        <Carousel fade>
                            <Carousel.Item>
                                <img className="d-block w-100" src={img5} alt="Service 1" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" src={img6} alt="Service 2" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" src={img7} alt="Service 3" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" src={img8} alt="Service 4" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" src={img9} alt="Service 5" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" src={img10} alt="Service 6" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" src={img11} alt="Service 7" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" src={img12} alt="Service 8" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" src={img13} alt="Service 9" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" src={img14} alt="Service 10" />
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <div className="services-info">
                        <p>We offer many different kinds of services, these are some of our most popular. If you don't see your required service, please contact us and our experts will take care of you, or point you in the right direction.</p>
                        <ul className="services-list">
                            <li>Winterization</li>
                            <li>Shrink Wrap</li>
                            <li>Tune Ups</li>
                            <li>Electrical Diagnostic</li>
                            <li>Engine Swaps</li>
                            <li>Outboard Repair</li>
                            <li>Jet Ski Repair</li>
                            <li>Prop Changes</li>
                            <li>Fiberglass and Gelcoat Repair</li>
                            <li>Stereo Installs</li>
                            <li>Trailer Wiring</li>
                            <li>Vinyl Wraps</li>
                            <li>Custom Wood Work</li>
                            <li>SeaDek Installs</li>
                            <li>Carpet Installs</li>
                            <li>Upholstery Work</li>
                            <li>Powder Coating</li>
                            <li>Boat Transport</li>
                            <li>And so much more!</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="contact-section" id="contact">
                <div className="contact-content">
                    <div className="contact-details">
                        <h3>Contact Information</h3>
                        <p>Phone Number: <a href="tel:+12536787750">(253) 678-7750</a></p>
                        <p>Email: <a href="mailto:BigWaveMarine253@gmail.com">BigWaveMarine253@gmail.com</a></p>
                        <h3>Serving Areas</h3>
                        <ul className="serving-areas-list">
                            <li>Olympia</li>
                            <li>Tacoma</li>
                            <li>Seattle</li>
                            <li>Everett</li>
                            <li>And surrounding areas</li>
                        </ul>
                    </div>
                    <div className="contact-hours">
                        <h3>Hours of Operation</h3>
                        <ul className="hours-list">
                            <li>Monday - Open 24/7</li>
                            <li>Tuesday - Open 24/7</li>
                            <li>Wednesday - Open 24/7</li>
                            <li>Thursday - Open 24/7</li>
                            <li>Friday - Open 24/7</li>
                            <li>Saturday - Open 24/7</li>
                            <li>Sunday - Open 24/7</li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default HomePage;
